<template>
  <v-card class="CardPanel v-card-panel" :class="{ 'py-0': cardTitle || !hasSlotDefault, 'pb-4': hasSlotDefault }">
    <v-card-title v-if="cardTitle" class="cardTitle" :class="[cardTitleClass, { hasSlotDefault: hasSlotDefault }]">
      <span v-html="cardTitle" />
      <Tooltip v-if="tooltipText" :text="tooltipText" :iconClass="'pl-2 ' + tooltipIconClass" />
    </v-card-title>
    <v-card-text v-if="hasSlotDefault" class="card-text-slot">
      <!-- 
        The slot is wrapped in a v-card-text as it affects the existing slyting on inner elements.
        It may be removed in future if it the styling is not needed.
      -->

      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CardPanel",
  props: {
    cardTitle: {
      type: String,
      default: "",
    },
    cardTitleClass: {
      type: String,
      default: "",
    },
    tooltipText: {
      type: String,
      default: "",
    },
    tooltipIconClass: {
      type: String,
      default: "",
    },
  },
  components: {
    Tooltip: () => import("./Tooltip.vue"),
  },
  computed: {
    hasSlotDefault() {
      return Boolean(this.$slots.default)
    },
  },
}
</script>

<style lang="scss" scoped>
$font-size: 0.925rem;

.CardPanel {
  font-size: $font-size;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  padding: 16px;
  margin: 4px; //that's necessary to show the elevate-4 shadow
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.2) -15px 20px 10px -7px !important;
  border: 1px solid #ececec;

  .cardTitle {
    margin-top: 0px !important;
    margin-bottom: -1px !important;
    margin-left: -16px !important;
    margin-right: -16px !important;

    padding-top: 16px !important;
    padding-bottom: 16px;
    padding-left: 16px !important;
    padding-right: 16px !important;
    ::v-deep() {
      opacity: 0.85 !important;
    }
  }

  .cardTitle.hasSlotDefault {
    margin-bottom: 6px !important;
  }

  > .v-card__text {
    padding: 0;
    color: inherit;
  }

  ::v-deep() {
    .v-card__text {
      padding-left: 0;
      padding-right: 0;
    }

    .v-card__actions, //This style should be removed. A future slot should be created to accept and style all action buttons
    .v-card__title:not(.cardTitle) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    p:not([class^="text-"]),
    .card-text-slot,
    input {
      line-height: 1.7rem;
      font-size: $font-size;
    }

    .theme--light.v-label {
      font-size: $font-size;
    }
  }
}
</style>
